/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Heading, Box } from '../components/ui'
import BlogPostFooter from '../components/BlogPostFooter'

const BlogPostMdx = ({ data, pageContext }) => {
  const { title, content, author, date, excerpt } = data.blogPost
  const authorString = author ? `by ${author} ` : ''
  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <Box variant="layout.padding">
        <Heading>{title}</Heading>
        <Heading as="h5">
          Posted {authorString}on {date}
        </Heading>
        <MDXRenderer>{content}</MDXRenderer>
      </Box>
      <BlogPostFooter next={pageContext.next} prev={pageContext.prev} />
    </Layout>
  )
}

export const query = graphql`
  query MdxBlogPostQuery($id: String!) {
    blogPost(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM Do YYYY")
      author
      excerpt
    }
  }
`

export default BlogPostMdx
