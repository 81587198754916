/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from './ui'

const BlogPostFooter = ({ next, prev }) => {
  return (
    <div sx={styles.container}>
      {prev ? (
        <div>
          ← <Link to={`/${prev.link}`}>{prev.title}</Link>
        </div>
      ) : null}
      {next ? (
        <div>
          <Link to={`/${next.link}`}>{next.title}</Link> →
        </div>
      ) : null}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default BlogPostFooter
